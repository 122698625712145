import React, { useState, useEffect } from "react";
import "./StyleConstructor.css";

function StyleConstructor() {
  const [settings, setSettings] = useState({
    size: 200,
    radius: 12,
    distance: 8,
    intensity: 1,
    blur: 15,
    background: "#e0e5ec",
    textColor: "#4d4d4d",
    accentColor: "#6b7b8e",
    fontSize: 16,
  });

  const [previewType, setPreviewType] = useState("button");
  const [cssCode, setCssCode] = useState("");
  const [htmlCode, setHtmlCode] = useState("");
  const [codeType, setCodeType] = useState("css");
  const [customText, setCustomText] = useState({
    buttonText: "Кнопка",
    cardTitle: "Карточка",
    cardDescription: "Пример карточки с неоморфным дизайном",
  });

  const previewTypes = [
    { id: "button", name: "Кнопка", icon: "🔲" },
    { id: "card", name: "Карточка", icon: "🗂️" },
    { id: "input", name: "Поле ввода", icon: "✏️" },
    { id: "switch", name: "Свитч", icon: "🔄" },
  ];

  const generateShadows = (isInset = false) => {
    const prefix = isInset ? "inset " : "";
    return `${prefix}${settings.distance}px ${settings.distance}px ${settings.blur}px rgba(163, 177, 198, ${settings.intensity}),
            ${prefix}-${settings.distance}px -${settings.distance}px ${settings.blur}px rgba(255, 255, 255, ${settings.intensity})`;
  };

  const getPreviewStyles = () => {
    const baseStyles = {
      background: settings.background,
      borderRadius: `${settings.radius}px`,
      color: settings.textColor,
      border: "none",
      transition: "all 0.3s ease",
    };

    switch (previewType) {
      case "button":
        return {
          ...baseStyles,
          padding: "15px 30px",
          cursor: "pointer",
          fontSize: `${settings.fontSize}px`,
          width: `${settings.size}px`,
          boxShadow: generateShadows(),
        };
      case "card":
        return {
          ...baseStyles,
          width: `${settings.size}px`,
          height: `${settings.size}px`,
          padding: "20px",
          boxShadow: generateShadows(),
          fontSize: `${settings.fontSize}px`,
        };
      case "input":
        return {
          ...baseStyles,
          padding: "15px",
          width: `${settings.size}px`,
          outline: "none",
          boxShadow: generateShadows(true),
        };
      case "switch":
        const switchWidth = settings.size;
        const switchHeight = Math.max(30, switchWidth / 2); // Минимальная высота 30px
        return {
          ...baseStyles,
          width: `${switchWidth}px`,
          height: `${switchHeight}px`,
          position: "relative",
          cursor: "pointer",
          boxShadow: generateShadows(true),
        };
      default:
        return baseStyles;
    }
  };

  const getSwitchKnobStyles = () => {
    const switchWidth = settings.size;
    const switchHeight = Math.max(30, switchWidth / 2);
    const knobSize = switchHeight - 4; // Размер кнобки чуть меньше высоты перключателя
    const knobOffset = switchWidth - knobSize - 4; // Расстояние для перемещения кнобки

    return {
      content: '""',
      position: "absolute",
      width: `${knobSize}px`,
      height: `${knobSize}px`,
      borderRadius: "50%",
      background: settings.background,
      top: "2px",
      left: "2px",
      boxShadow: generateShadows(),
      transition: "transform 0.3s ease",
      transform: "translateX(0)",
      cursor: "pointer",
    };
  };

  const handleSwitchClick = (e) => {
    const knob = e.target;
    const switchWidth = settings.size;
    const switchHeight = Math.max(30, switchWidth / 2);
    const knobSize = switchHeight - 4;
    const knobOffset = switchWidth - knobSize - 4;

    const isActive = knob.style.transform === `translateX(${knobOffset}px)`;
    knob.style.transform = isActive
      ? "translateX(0)"
      : `translateX(${knobOffset}px)`;
  };

  useEffect(() => {
    generateCSS();
    generateHTML();
  }, [settings, previewType, customText]);

  const generateCSS = () => {
    const cssVariables = `/* CSS переменные */
:root {
  --shadow-intensity: 1;
  --shadow-dark: rgba(163, 177, 198, ${settings.intensity});
  --shadow-light: rgba(255, 255, 255, ${settings.intensity});
  --background: ${settings.background};
  --text-color: ${settings.textColor};
  --accent-color: ${settings.accentColor};
  --border-radius: ${settings.radius}px;
}`;

    const commonStyles = `
  background: var(--background);
  border-radius: var(--border-radius);
  color: var(--text-color);`;

    const shadowsOut = `
  box-shadow: calc(${settings.distance}px * var(--shadow-intensity)) calc(${settings.distance}px * var(--shadow-intensity)) calc(${settings.blur}px * var(--shadow-intensity)) var(--shadow-dark),
              calc(-${settings.distance}px * var(--shadow-intensity)) calc(-${settings.distance}px * var(--shadow-intensity)) calc(${settings.blur}px * var(--shadow-intensity)) var(--shadow-light);`;

    const shadowsIn = `
  box-shadow: inset calc(${settings.distance}px * var(--shadow-intensity)) calc(${settings.distance}px * var(--shadow-intensity)) calc(${settings.blur}px * var(--shadow-intensity)) var(--shadow-dark),
              inset calc(-${settings.distance}px * var(--shadow-intensity)) calc(-${settings.distance}px * var(--shadow-intensity)) calc(${settings.blur}px * var(--shadow-intensity)) var(--shadow-light);`;

    let specificStyles = "";
    switch (previewType) {
      case "button":
        specificStyles = `${cssVariables}

.neo-button {${commonStyles}
  width: ${settings.size}px;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: ${settings.fontSize}px;
  transition: all 0.3s ease;${shadowsOut}
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.neo-button:hover,
.neo-button:active,
.neo-button:focus {
  color: var(--accent-color);
  outline: none;
}

.neo-button:active {${shadowsIn}}`;
        break;
      case "card":
        specificStyles = `${cssVariables}

.neo-card {${commonStyles}
  width: ${settings.size}px;
  height: ${settings.size}px;
  padding: 20px;
  font-size: ${settings.fontSize}px;${shadowsOut}
}`;
        break;
      case "input":
        specificStyles = `${cssVariables}

.neo-input {${commonStyles}${shadowsIn}
  border: none;
  padding: 15px;
  width: ${settings.size}px;
  outline: none;
}`;
        break;
      case "switch":
        const switchWidth = settings.size;
        const switchHeight = Math.max(30, switchWidth / 2);
        const knobSize = switchHeight - 4;
        const knobOffset = switchWidth - knobSize - 4;

        specificStyles = `${cssVariables}

.neo-switch {${commonStyles}
  width: ${switchWidth}px;
  height: ${switchHeight}px;
  position: relative;
  cursor: pointer;${shadowsIn}
}

.neo-switch::before {
  content: '';
  position: absolute;
  width: ${knobSize}px;
  height: ${knobSize}px;
  border-radius: 50%;
  background: var(--background);
  top: 2px;
  left: 2px;${shadowsOut}
  transition: transform 0.3s ease;
}

.neo-switch.active::before {
  transform: translateX(${knobOffset}px);
}`;
        break;
      default:
        break;
    }
    setCssCode(specificStyles);
  };

  const generateHTML = () => {
    let code = "";
    switch (previewType) {
      case "button":
        code = `<button class="neo-button">
  ${customText.buttonText}
</button>`;
        break;
      case "card":
        code = `<div class="neo-card">
  <h3>${customText.cardTitle}</h3>
  <p>${customText.cardDescription}</p>
</div>`;
        break;
      case "input":
        code = `<input 
  type="text"
  class="neo-input"
  placeholder="Введите текст..."
/>`;
        break;
      case "switch":
        code = `<div class="neo-switch">
  <!-- Переключатель -->
</div>

<script>
  document.querySelector('.neo-switch').addEventListener('click', function() {
    this.classList.toggle('active');
  });
</script>`;
        break;
      default:
        break;
    }
    setHtmlCode(code);
  };

  const handleChange = (name, value) => {
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const copyToClipboard = () => {
    const codeToCopy = codeType === "css" ? cssCode : htmlCode;
    navigator.clipboard.writeText(codeToCopy);
    alert(codeType.toUpperCase() + " код скопирован в буфер обмена!");
  };

  const renderPreview = () => {
    const previewStyles = getPreviewStyles();

    switch (previewType) {
      case "button":
        return (
          <button
            className="preview-element"
            style={previewStyles}
            onMouseEnter={(e) => (e.target.style.color = settings.accentColor)}
            onMouseLeave={(e) => (e.target.style.color = settings.textColor)}
            onMouseDown={(e) =>
              (e.target.style.boxShadow = generateShadows(true))
            }
            onMouseUp={(e) => (e.target.style.boxShadow = generateShadows())}
            onTouchStart={(e) => {
              e.preventDefault();
              e.target.style.boxShadow = generateShadows(true);
              e.target.style.color = settings.accentColor;
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              e.target.style.boxShadow = generateShadows();
              e.target.style.color = settings.textColor;
            }}
            contentEditable="true"
            suppressContentEditableWarning={true}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
                const selection = window.getSelection();
                const range = selection.getRangeAt(0);
                const space = document.createTextNode("\u00a0");
                range.insertNode(space);
                range.setStartAfter(space);
                range.setEndAfter(space);
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }}
            onBlur={(e) => handleTextChange("buttonText", e.target.textContent)}
            dangerouslySetInnerHTML={{ __html: customText.buttonText }}
          />
        );
      case "card":
        return (
          <div className="preview-element" style={previewStyles}>
            <h3
              contentEditable="true"
              style={{ color: settings.accentColor }}
              suppressContentEditableWarning={true}
              onBlur={(e) => handleTextChange("cardTitle", e.target.textContent)}
              dangerouslySetInnerHTML={{ __html: customText.cardTitle }}
            />
            <p
              contentEditable="true"
              suppressContentEditableWarning={true}
              onBlur={(e) => handleTextChange("cardDescription", e.target.textContent)}
              dangerouslySetInnerHTML={{ __html: customText.cardDescription }}
            />
          </div>
        );
      case "input":
        return (
          <input
            type="text"
            className="preview-element"
            style={previewStyles}
            placeholder="Введите текст..."
          />
        );
      case "switch":
        return (
          <div
            className="preview-element switch-container"
            style={previewStyles}
          >
            <div
              className="switch-knob"
              style={getSwitchKnobStyles()}
              onClick={handleSwitchClick}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const generateFullTemplate = () => {
    return `<!DOCTYPE html>
<html lang="ru">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Неоморфный ${
      previewType === "button"
        ? "Button"
        : previewType === "card"
        ? "Card"
        : previewType === "input"
        ? "Input"
        : "Switch"
    }</title>
    <style>
${cssCode}

/* Базовые стили страницы */
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    background: var(--background);
}
    </style>
</head>
<body>
    ${htmlCode}
</body>
</html>`;
  };

  const copyFullTemplate = () => {
    navigator.clipboard.writeText(generateFullTemplate());
    alert("Полный HTML шаблон скопирован в буфер обмена!");
  };

  const handleTextChange = (type, value) => {
    setCustomText(prev => ({
      ...prev,
      [type]: value
    }));
  };

  return (
    <div className="constructor-section">
      <h2>Конструктор Стилей</h2>
      <p className="subtitle">Создайте свой неоморфный дизайн</p>

      <div className="constructor-container">
        <div className="constructor-header">
          <div className="type-selector">
            {previewTypes.map((type) => (
              <button
                key={type.id}
                className={`neo-button type-button ${
                  previewType === type.id ? "active" : ""
                }`}
                onClick={() => setPreviewType(type.id)}
              >
                <span className="type-icon">{type.icon}</span>
                {type.name}
              </button>
            ))}
          </div>
        </div>

        <div className="constructor-main">
          <div className="settings-panel">
            <div className="settings-group">
              <h3>Размеры</h3>
              <div className="control-group">
                <label>Размер (px)</label>
                <input
                  type="range"
                  min="100"
                  max="400"
                  value={settings.size}
                  onChange={(e) => handleChange("size", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.size}px</span>
              </div>

              <div className="control-group">
                <label>Скругление углов (px)</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={settings.radius}
                  onChange={(e) => handleChange("radius", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.radius}px</span>
              </div>
            </div>

            <div className="settings-group">
              <h3>Типографика</h3>
              <div className="control-group">
                <label>Размер шрифта (px)</label>
                <input
                  type="range"
                  min="5"
                  max="32"
                  value={settings.fontSize}
                  onChange={(e) => handleChange("fontSize", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.fontSize}px</span>
              </div>
            </div>

            <div className="settings-group">
              <h3>Тени</h3>
              <div className="control-group">
                <label>Расстояние (px)</label>
                <input
                  type="range"
                  min="2"
                  max="20"
                  value={settings.distance}
                  onChange={(e) => handleChange("distance", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.distance}px</span>
              </div>

              <div className="control-group">
                <label>Интенсивность</label>
                <input
                  type="range"
                  min="0.1"
                  max="1"
                  step="0.1"
                  value={settings.intensity}
                  onChange={(e) => handleChange("intensity", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.intensity}</span>
              </div>

              <div className="control-group">
                <label>Размытие (px)</label>
                <input
                  type="range"
                  min="5"
                  max="30"
                  value={settings.blur}
                  onChange={(e) => handleChange("blur", e.target.value)}
                  className="neo-range"
                />
                <span className="value-display">{settings.blur}px</span>
              </div>
            </div>

            <div className="settings-group">
              <h3>Цвета</h3>
              <div className="color-controls">
                <div className="control-group">
                  <label>Фон</label>
                  <input
                    type="color"
                    value={settings.background}
                    onChange={(e) => handleChange("background", e.target.value)}
                    className="neo-color"
                  />
                </div>

                <div className="control-group">
                  <label>Текст</label>
                  <input
                    type="color"
                    value={settings.textColor}
                    onChange={(e) => handleChange("textColor", e.target.value)}
                    className="neo-color"
                  />
                </div>

                <div className="control-group">
                  <label>Акцент</label>
                  <input
                    type="color"
                    value={settings.accentColor}
                    onChange={(e) =>
                      handleChange("accentColor", e.target.value)
                    }
                    className="neo-color"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="preview-section">
            <div
              className="preview-container"
              style={{ background: settings.background }}
            >
              {renderPreview()}
            </div>
            <div className="code-container">
              <div className="code-type-selector">
                <button
                  className={
                    "neo-button type-button " +
                    (codeType === "html" ? "active" : "")
                  }
                  onClick={() => setCodeType("html")}
                >
                  HTML
                </button>
                <button
                  className={
                    "neo-button type-button " +
                    (codeType === "css" ? "active" : "")
                  }
                  onClick={() => setCodeType("css")}
                >
                  CSS
                </button>
              </div>
              <pre className="css-code">
                {codeType === "css" ? cssCode : htmlCode}
              </pre>
              <div className="button-group">
                <button
                  className="neo-button copy-button"
                  onClick={copyToClipboard}
                >
                  Копировать {codeType.toUpperCase()}
                </button>
                <button
                  className="neo-button copy-button full-template"
                  onClick={copyFullTemplate}
                >
                  Копировать полный шаблон
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StyleConstructor;
