import React, { useState } from 'react';
import MediaPlayer from '../components/examples/MediaPlayer';
import Calculator from '../components/examples/Calculator';

import './ExamplesPage.css';

function ExamplesPage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const examples = [
    {
      title: "Музыкальный плеер",
      component: <MediaPlayer />
    },
    {
      title: "Калькулятор",
      component: <Calculator />
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % examples.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + examples.length) % examples.length);
  };

  return (
    <div className="examples-page">
      <h1>Примеры неоморфного дизайна</h1>

      <div className="gallery-container">
        <button className="gallery-nav gallery-nav-prev" onClick={prevSlide}>
          &#8249;
        </button>
        
        <div className="gallery-content">
          <h2>{examples[currentSlide].title}</h2>
          <div className="device-container">
            {examples[currentSlide].component}
          </div>
        </div>

        <button className="gallery-nav gallery-nav-next" onClick={nextSlide}>
          &#8250;
        </button>
      </div>
    </div>
  );
}

export default ExamplesPage; 