import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ExamplesPage from './pages/ExamplesPage';
import './App.css';

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="app">
      <header className="main-header">
        <nav className="main-nav">
          <div className="nav-content">
            <NavLink to="/" className="nav-logo">
              <img src="/logo.svg" alt="TheControlPanel" />
            </NavLink>
            <button className="mobile-menu-button" onClick={toggleMobileMenu}>
              <i className={`fas ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
            <div className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
              <NavLink to="/" 
                className={({ isActive }) => isActive ? 'active' : ''}
                onClick={() => setIsMobileMenuOpen(false)}>
                Конструктор
              </NavLink>
              <NavLink to="/examples" 
                className={({ isActive }) => isActive ? 'active' : ''}
                onClick={() => setIsMobileMenuOpen(false)}>
                Примеры
              </NavLink>
              <NavLink to="/about" 
                className={({ isActive }) => isActive ? 'active' : ''}
                onClick={() => setIsMobileMenuOpen(false)}>
                О Неоморфизме
              </NavLink>
            </div>
          </div>
        </nav>
      </header>

      <main className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/examples" element={<ExamplesPage />} />
        </Routes>
      </main>

      <footer className="main-footer">
        <div className="footer-content">
          <p>© 2024 TheControlPanel. Все права защищены.</p>
          <div className="footer-links">
            <a href="https://github.com" target="_blank" rel="noopener noreferrer">GitHub</a>
            <a href="#" onClick={(e) => e.preventDefault()}>Политика конфиденциальности</a>
            <a href="#" onClick={(e) => e.preventDefault()}>Условия использования</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
