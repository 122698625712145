import React from 'react';
import StyleConstructor from '../components/StyleConstructor';
import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <section className="hero-section">
        <h1>Неоморфный Конструктор</h1>
        <p className="subtitle">
          Создавайте современные неоморфные элементы интерфейса без написания кода
        </p>
        <div className="features">
          <div className="feature">
            <span className="icon">🎨</span>
            <h3>Визуальный Редактор</h3>
            <p>Настраивайте внешний вид элементов в реальном времени</p>
          </div>
          <div className="feature">
            <span className="icon">📱</span>
            <h3>Адаптивный Дизайн</h3>
            <p>Элементы отлично выглядят на всех устройствах</p>
          </div>
          <div className="feature">
            <span className="icon">💻</span>
            <h3>Готовый Код</h3>
            <p>Получите чистый CSS и HTML код для вашего проекта</p>
          </div>
        </div>
      </section>

      <StyleConstructor />
      
      <section className="cta-section">
        <h2>Начните Создавать</h2>
        <p>
          Используйте конструктор выше, чтобы создать свой первый неоморфный элемент.
          Настройте цвета, размеры и тени по вашему вкусу.
        </p>
      </section>
    </div>
  );
}

export default HomePage; 