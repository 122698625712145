import React from 'react';
import './AboutPage.css';

function AboutPage() {
  return (
    <div className="about-page">
      <section className="intro-section">
        <h1>О Неоморфизме</h1>
        <p className="subtitle">
          Современный подход к дизайну интерфейсов, сочетающий минимализм и объемность
        </p>
      </section>

      <section className="history-section">
        <h2>История Появления</h2>
        <p>
          Неоморфизм (или Soft UI) появился как эволюция скевоморфизма и плоского дизайна.
          Этот стиль был впервые представлен в конце 2019 года и быстро набрал популярность
          благодаря своей уникальной эстетике и способности создавать мягкие, реалистичные интерфейсы.
        </p>
      </section>

      <section className="principles-section">
        <h2>Основные Принципы</h2>
        <div className="principles-grid">
          <div className="principle">
            <h3>Мягкие Тени</h3>
            <p>
              Использование светлых и темных теней для создания эффекта объемности
              без резких контрастов.
            </p>
          </div>
          <div className="principle">
            <h3>Монохромность</h3>
            <p>
              Работа в пределах одной цветовой гаммы с минимальными вариациями
              для создания целостного образа.
            </p>
          </div>
          <div className="principle">
            <h3>Тонкие Градиенты</h3>
            <p>
              Использование едва заметных градиентов для усиления эффекта
              объемности и реалистичности.
            </p>
          </div>
          <div className="principle">
            <h3>Интерактивность</h3>
            <p>
              Особое внимание к состояниям элементов при взаимодействии:
              нажатие, наведение, фокус.
            </p>
          </div>
        </div>
      </section>

      <section className="usage-section">
        <h2>Применение</h2>
        <div className="usage-cases">
          <div className="case">
            <h3>Веб-приложения</h3>
            <p>
              Идеально подходит для дашбордов, панелей управления и других
              интерфейсов, где важна визуальная иерархия.
            </p>
          </div>
          <div className="case">
            <h3>Мобильные Интерфейсы</h3>
            <p>
              Создает приятный пользовательский опыт в мобильных приложениях,
              особенно в светлых темах.
            </p>
          </div>
          <div className="case">
            <h3>Сайты-портфолио</h3>
            <p>
              Помогает создать современный и запоминающийся дизайн для
              творческих проектов.
            </p>
          </div>
        </div>
      </section>

      <section className="tips-section">
        <h2>Советы по Использованию</h2>
        <ul className="tips-list">
          <li>
            <strong>Контраст:</strong> Убедитесь, что элементы достаточно контрастны
            для хорошей читаемости.
          </li>
          <li>
            <strong>Доступность:</strong> Учитывайте пользователей с особыми
            потребностями при выборе цветов и размеров.
          </li>
          <li>
            <strong>Производительность:</strong> Оптимизируйте тени и эффекты
            для быстрой работы на мобильных устройствах.
          </li>
          <li>
            <strong>Совместимость:</strong> Проверяйте работу в разных браузерах
            и на разных устройствах.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default AboutPage; 