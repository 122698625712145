import React, { useState, useEffect, useCallback } from 'react';
import './MediaPlayer.css';

const tracks = [
  {
    title: "Lose it",
    artist: "Flume ft. Vic Mensa",
    cover: "/album-art-1.png",
    duration: 226
  },
  {
    title: "Never Be Like You",
    artist: "Flume ft. Kai",
    cover: "/album-art-2.png",
    duration: 234
  },
  {
    title: "Say It",
    artist: "Flume ft. Tove Lo",
    cover: "/album-art-3.png",
    duration: 250
  }
];

function MediaPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const progressBar = document.querySelector('.progress-bar');
      const rect = progressBar.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setCurrentTime(Math.min(Math.max(percentage, 0), 100));
    }
  }, [isDragging]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    let interval;
    if (isPlaying && !isDragging) {
      interval = setInterval(() => {
        setCurrentTime(prev => {
          if (prev >= 100) {
            nextTrack();
            return 0;
          }
          return prev + 0.5;
        });
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isPlaying, isDragging]);

  const formatTime = (percentage) => {
    const duration = tracks[currentTrack].duration;
    const seconds = Math.floor((percentage / 100) * duration);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleProgressClick = (e) => {
    if (!isDragging) {
      const progressBar = e.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setCurrentTime(Math.min(Math.max(percentage, 0), 100));
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const nextTrack = () => {
    setCurrentTrack((prev) => (prev + 1) % tracks.length);
    setCurrentTime(0);
  };

  const prevTrack = () => {
    setCurrentTrack((prev) => (prev - 1 + tracks.length) % tracks.length);
    setCurrentTime(0);
  };

  return (
    <div className="player-container">
      <div className="neo-player">
        <div className="player-header">
          <button className="header-button">
            <i className="fa-solid fa-angle-left"></i>
          </button>
          <span className="now-playing">PLAYING NOW</span>
          <button className="header-button">
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>

        <div className="track-art">
          <img src={tracks[currentTrack].cover} alt="Album Art" />
        </div>

        <div className="track-info">
          <h2>{tracks[currentTrack].title}</h2>
          <p>{tracks[currentTrack].artist}</p>
        </div>

        <div className="progress-container">
          <div 
            className="progress-bar"
            onClick={handleProgressClick}
          >
            <div 
              className="progress-current" 
              style={{ width: `${currentTime}%` }}
            >
              <div 
                className="progress-knob"
                onMouseDown={handleMouseDown}
              ></div>
            </div>
          </div>
          <div className="time-info">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(100)}</span>
          </div>
        </div>

        <div className="player-controls">
          <button className="control-button" onClick={prevTrack}>
            <i className="fa-solid fa-backward-step"></i>
          </button>
          <button 
            className={`control-button play-button ${isPlaying ? 'active' : ''}`}
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <i className={`fa-solid ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
          </button>
          <button className="control-button" onClick={nextTrack}>
            <i className="fa-solid fa-forward-step"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MediaPlayer; 