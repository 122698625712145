import React, { useState } from 'react';
import './Calculator.css';

function Calculator() {
  const [display, setDisplay] = useState('0');
  const [equation, setEquation] = useState('');

  const handleNumber = (number) => {
    if (display === '0') {
      setDisplay(number);
    } else {
      setDisplay(display + number);
    }
  };

  const handleOperator = (operator) => {
    setEquation(display + ' ' + operator + ' ');
    setDisplay('0');
  };

  const handleEqual = () => {
    try {
      const result = eval(equation + display);
      setDisplay(result.toString());
      setEquation('');
    } catch (error) {
      setDisplay('Error');
    }
  };

  const handleClear = () => {
    setDisplay('0');
    setEquation('');
  };

  return (
    <div className="calculator-container">
      <div className="neo-calculator">
        <div className="calculator-display">
          <div className="previous-operand">{equation}</div>
          <div className="current-operand">{display}</div>
        </div>
        <div className="calculator-buttons">
          <button className="calculator-button" onClick={handleClear}>C</button>
          <button className="calculator-button">±</button>
          <button className="calculator-button" onClick={() => handleOperator('%')}>%</button>
          <button className="calculator-button operator" onClick={() => handleOperator('/')}>÷</button>
          
          <button className="calculator-button" onClick={() => handleNumber('7')}>7</button>
          <button className="calculator-button" onClick={() => handleNumber('8')}>8</button>
          <button className="calculator-button" onClick={() => handleNumber('9')}>9</button>
          <button className="calculator-button operator" onClick={() => handleOperator('*')}>×</button>
          
          <button className="calculator-button" onClick={() => handleNumber('4')}>4</button>
          <button className="calculator-button" onClick={() => handleNumber('5')}>5</button>
          <button className="calculator-button" onClick={() => handleNumber('6')}>6</button>
          <button className="calculator-button operator" onClick={() => handleOperator('-')}>−</button>
          
          <button className="calculator-button" onClick={() => handleNumber('1')}>1</button>
          <button className="calculator-button" onClick={() => handleNumber('2')}>2</button>
          <button className="calculator-button" onClick={() => handleNumber('3')}>3</button>
          <button className="calculator-button operator" onClick={() => handleOperator('+')}>+</button>
          
          <button className="calculator-button zero" onClick={() => handleNumber('0')}>0</button>
          <button className="calculator-button" onClick={() => handleNumber('.')}>.</button>
          <button className="calculator-button operator" onClick={handleEqual}>=</button>
        </div>
      </div>
    </div>
  );
}

export default Calculator; 